import { Image } from '../../Albums/types';

export const toImageFireStoreModel = (model: Image) => {
  return {
    id: model.id,
  };
};

export const toImageInternalModel = (firestore: {
  [k: string]: string;
}): Image => {
  return {
    id: firestore.id,
  };
};
