import { css } from '@emotion/css';
import { DAY_WE_MET, START_OF_RELATIONSHIP, timeSince } from './utils';

const timeClassName = css({
  display: 'flex',
});

const sectionClassName = css({
  fontSize: '14px',
});

const getTimeString = (duration: Duration): string => {
  let res = '';

  if (duration.years) {
    res +=
      String(duration.years) +
      ' ' +
      (duration.years === 1 ? 'year' : 'years') +
      ' ';
  }

  if (duration.months) {
    res +=
      String(duration.months) +
      ' ' +
      (duration.months === 1 ? 'month' : 'months') +
      ' ';
  }

  if (duration.days) {
    res += String(duration.days) + ' ' + (duration.days === 1 ? 'day' : 'days');
  }

  return res;
};

const TimeTogether = () => {
  const timeInRelationship = timeSince(START_OF_RELATIONSHIP);
  const timeSinceMeeting = timeSince(DAY_WE_MET);
  return (
    <div className={sectionClassName}>
      <b className={timeClassName}>
        Together for {getTimeString(timeInRelationship)}
      </b>
      <b className={timeClassName}>
        We met {getTimeString(timeSinceMeeting)} ago
      </b>
    </div>
  );
};

export default TimeTogether;
