import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import useUploadImages from './useUploadImages';
import { processBlob } from '../../utils';
import { css } from '@emotion/css';

const thumbnailImageClassName = css({
  width: '75px',
  height: '75px',
  objectFit: 'cover',
  paddingRight: '8px',
});

const Thumbnail = ({ img }: { img: File }) => {
  const [data, setData] = useState<string>(window.URL.createObjectURL(img));

  useEffect(() => {
    const getImage = async () => {
      const res = await processBlob(img);
      setData(window.URL.createObjectURL(res));
    };

    getImage();
  }, [setData, img]);

  return (
    <div>
      <img className={thumbnailImageClassName} src={data} />
    </div>
  );
};

const dropzoneClassName = css({
  backgroundColor: '#ebebeb',
  borderRadius: '8px',
  cursor: 'pointer',
  border: '1px dashed grey',
  display: 'flex',
  height: '64px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
});

const modalClassName = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const imageThumbnailsClassName = css({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '16px',
});

const UploadButton = () => {
  const [images, setImages] = useState<File[]>([]);
  const [visible, setVisible] = useState(false);
  const { uploadImages, status } = useUploadImages();

  const onDrop = (acceptedFiles: File[]) => {
    setImages([...images, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const closeModal = () => {
    setVisible(false);
    setImages([]);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <UploadOutlined />
      </Button>
      <Modal
        onCancel={closeModal}
        visible={visible}
        onOk={async () => {
          try {
            await uploadImages(images);
            closeModal();
          } catch {}
        }}
        okButtonProps={{
          loading: status === 'Uploading',
        }}
        width='auto'
      >
        <div {...getRootProps()} className={modalClassName}>
          <input {...getInputProps()} />
          <div className={dropzoneClassName}>
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag 'n drop some files here, or click to select files</p>
            )}
          </div>
          <div className={imageThumbnailsClassName}>
            {images.map((img) => (
              <Thumbnail key={img.name} img={img} />
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadButton;
