import { css } from '@emotion/css';
import React from 'react';

const playlistClassName = css({
  height: '500px',
});

const Playlist: React.FC = () => (
  <iframe
    className={playlistClassName}
    src='https://open.spotify.com/embed/playlist/5JjKsgJtX971KUT6V5ElYR?utm_source=generator'
    width='100%'
    height='380'
    frameBorder='0'
    allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
    title='playlist'
  ></iframe>
);

export default Playlist;
