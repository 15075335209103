import { query, where } from 'firebase/firestore';
import { MESSAGES_COLLECTION } from '../firebase/constants';
import useFirebaseQuery from '../firebase/useFirebaseQuery';
import useUser from '../Users/Authorize/useUser';
import { Message } from './types';

const useMessages = (): Message[] => {
  const user = useUser();
  const messagesQuery = query(
    MESSAGES_COLLECTION,
    where('to', '==', user.email),
    where('seen', '==', false)
  );

  const data = useFirebaseQuery(messagesQuery);

  if (!data) {
    return [];
  }

  return data.map((doc) => doc.data());
};

export default useMessages;
