import {
  DocumentReference,
  DocumentSnapshot,
  onSnapshot,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';

const useFirebaseDoc = <T>(docRef: DocumentReference<T>): T | undefined => {
  const [data, setData] = useState<T>();

  useEffect(() => {
    const unsubscribe = onSnapshot(docRef, (document: DocumentSnapshot<T>) => {
      setData(document.data());
    });

    return () => unsubscribe();
  }, []);

  return data;
};

export default useFirebaseDoc;
