import { useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { AUTH } from '../../firebase/constants';
import { useNavigate } from 'react-router-dom';

type Output =
  | { status: 'LoggedIn'; user: User }
  | { status: 'LoggedOut' }
  | { status: 'Loading' };

const useLoginRedirectEffect = (): Output => {
  const [data, setData] = useState<Output>({ status: 'Loading' });
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(AUTH, (user) => {
      if (!user) {
        navigate('login');
        setData({ status: 'LoggedOut' });
      } else {
        setData({ status: 'LoggedIn', user });
      }
    });
  }, []);

  return data;
};

export default useLoginRedirectEffect;
