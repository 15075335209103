import { CloseOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Checkbox } from 'antd';
import React from 'react';
import { setListItemStatus, removeFromList } from '../../firebase/utils/lists';
import { List, ListItem } from '../types';
import AddLinkButton from './AddLinkButton';

const itemClassName = css({
  display: 'flex',
  padding: '8px 0',
});

const actionButtonsClassName = css({
  marginLeft: 'auto',
});

interface Props {
  item: ListItem;
  list: List;
}

const getUsableLink = (link: string): string => {
  if (!/(http(s?)):\/\//i.test(link)) {
    return `https://${link}`;
  }

  return link;
};

const ItemComponent: React.FC<Props> = ({ item, list }) => {
  if (item.link) {
    return (
      <div className={itemClassName}>
        <Checkbox
          checked={item.done}
          onChange={(e) => setListItemStatus(list, item, e.target.checked)}
        />
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={getUsableLink(item.link)}
        >
          {item.item}
        </a>
        <CloseOutlined
          onClick={() => removeFromList(list, item)}
          className={actionButtonsClassName}
        />
      </div>
    );
  }

  return (
    <div className={itemClassName}>
      <Checkbox
        checked={item.done}
        onChange={(e) => setListItemStatus(list, item, e.target.checked)}
      />
      {item.item}
      <div className={actionButtonsClassName}>
        <AddLinkButton list={list} item={item} />
        <CloseOutlined onClick={() => removeFromList(list, item)} />
      </div>
    </div>
  );
};

export default ItemComponent;
