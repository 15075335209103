import React, { useState } from 'react';

import { Button, Form, Input, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import useIsLoggedIn from './useIsLoggedIn';
import { signIn } from '../../firebase/utils/signin';
import { css } from '@emotion/css';

const loginPageClassName = css({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: 'lavender',
});

const loginFormClassName = css({
  maxWidth: '600px',
  width: '90%',
});

const emailFieldClassName = css({
  '&&': {
    marginBottom: '8px',
  },
});

const signInButtonClassName = css({
  width: '100%',
  display: 'flex',
  marginTop: '16px',
  justifyContent: 'center',
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn) {
    navigate('/');
  }

  return (
    <div className={loginPageClassName}>
      <Form
        className={loginFormClassName}
        onFinish={async () => {
          setLoading(true);
          const result = await signIn(email, password);

          switch (result.status) {
            case 'Success':
              navigate('/');
              break;
            case 'Error':
              notification.error({ message: 'Failed to login' });
          }

          setLoading(false);
        }}
      >
        <h1>Login</h1>
        <Input
          value={email}
          placeholder='Email'
          onChange={(event) => setEmail(event.target.value)}
          className={emailFieldClassName}
        />
        <Input
          type='password'
          value={password}
          placeholder='Password'
          onChange={(event) => setPassword(event.target.value)}
        />
        <div className={signInButtonClassName}>
          <Button htmlType='submit' loading={loading}>
            Sign In
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Login;
