import { Spin } from 'antd';
import React from 'react';
import useLoginRedirectEffect from './useLoginRedirectEffect';
import { UserContext } from './useUser';

const Authorize: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const data = useLoginRedirectEffect();

  switch (data.status) {
    case 'Loading':
      return <Spin />;
    case 'LoggedIn':
      return (
        <UserContext.Provider value={data.user}>
          {children}
        </UserContext.Provider>
      );
    case 'LoggedOut':
      return null;
  }
};

export default Authorize;
