import { Route, Routes } from 'react-router-dom';
import AlbumPage from './Albums/AlbumPage';
import AlbumsPage from './Albums/AlbumsPage';
import App from './App';
import Homepage from './Homepage/Homepage';
import Login from './Users/Login/Login';

const AppRoutes = () => (
  <Routes>
    <Route path='/' element={<App />}>
      <Route index element={<Homepage />} />
      <Route path='login' element={<Login />} />
      <Route path='albums' element={<AlbumsPage />} />
      <Route path='album/:albumId' element={<AlbumPage />} />
      <Route
        path='*'
        element={
          <main style={{ padding: '1rem' }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Route>
  </Routes>
);

export default AppRoutes;
