import { css } from '@emotion/css';
import { ReactNode } from 'react';
import SendMessageButton from './Messages/SendMessageButton';

interface Props {
  title: string;
  subtitle?: string;
  rightContent?: ReactNode;
}

const pageHeaderClassName = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 32px 0 32px',
});

const pageHeaderTitleRowClassName = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',

  h1: {
    marginBottom: 0,
  },
});

const rightSectionClassName = css({
  display: 'flex',
});

const PageHeader: React.FC<Props> = ({ title, rightContent, subtitle }) => (
  <div className={pageHeaderClassName}>
    <div className={pageHeaderTitleRowClassName}>
      <h1>{title}</h1>
      <div className={rightSectionClassName}>
        {rightContent}
        <SendMessageButton />
      </div>
    </div>
    {subtitle && <h3>{subtitle}</h3>}
  </div>
);

export default PageHeader;
