import { FirestoreDataConverter } from 'firebase/firestore';
import { List } from '../../Lists/types';

const listConverter: FirestoreDataConverter<List> = {
  toFirestore: (model) => {
    return {
      title: model.title,
      description: model.description,
      data: model.data,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data();
    return {
      description: data.description,
      title: data.title,
      id: snapshot.id,
      data: data.data,
    };
  },
};

export default listConverter;
