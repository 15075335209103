import { ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { IMAGES_STORAGE_PATH, STORAGE } from '../../../firebase/constants';
import { addImages } from '../../../firebase/utils/albums';
import { Album } from '../../types';
import useAlbum from '../useAlbum';

type Status = 'None' | 'Uploading' | 'Success' | 'Error';

interface Output {
  uploadImages: (images: File[]) => Promise<void>;
  status: Status;
}

const getImagePromise = async (file: File, album: Album): Promise<string> => {
  const fileId = uuid();
  const storageRef = ref(STORAGE, `${IMAGES_STORAGE_PATH}/${fileId}`);

  await uploadBytes(storageRef, file);

  return fileId;
};

const useUploadImages = (): Output => {
  const [status, setStatus] = useState<Status>('None');
  const data = useAlbum();

  const album = (data as unknown as { album: Album }).album;

  const uploadImages = async (files: File[]) => {
    setStatus('Uploading');

    const promises = files.map((file) => getImagePromise(file, album));

    try {
      const res = await Promise.all(promises);
      await addImages(album, res);
      setStatus('Success');
      return Promise.resolve();
    } catch (e) {
      setStatus('Error');
      return Promise.reject();
    }
  };

  return {
    status,
    uploadImages,
  };
};

export default useUploadImages;
