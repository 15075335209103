import { ReactNode } from 'react';
import { css } from '@emotion/css';

const containerClassName = css({
  //   width: '90%',
  maxWidth: '800px',
  minWidth: '500px',
  padding: '32px',
});

const Module: React.FC<{ children: ReactNode }> = ({ children }) => (
  <div className={containerClassName}>{children}</div>
);

export default Module;
