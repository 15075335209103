import { getBlob, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { STORAGE, IMAGES_STORAGE_PATH } from '../../firebase/constants';
import { Image } from '../types';
import { processBlob } from '../utils';

const useImage = (image: Image) => {
  const [data, setData] = useState({ data: '' });

  useEffect(() => {
    const fetchImageData = async () => {
      const storageRef = ref(STORAGE, `${IMAGES_STORAGE_PATH}/${image.id}`);
      const blob = await getBlob(storageRef);
      const processedBlob = await processBlob(blob);

      setData({ data: window.URL.createObjectURL(processedBlob) });
    };

    fetchImageData();
  }, [image]);

  return data;
};

export default useImage;
