import { MailOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, notification } from 'antd';
import { useState } from 'react';
import { sendMessage } from '../firebase/utils/messages';
import useUser from '../Users/Authorize/useUser';
import { getSendTo } from './utils';

const SendMessageButton = () => {
  const user = useUser();
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const sendTo = getSendTo(user);

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <MailOutlined />
      </Button>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        cancelButtonProps={{ style: { visibility: 'hidden' } }}
        okButtonProps={{
          htmlType: 'submit',
          loading: sending,
        }}
        okText={<SendOutlined />}
        onOk={async () => {
          setSending(true);
          await sendMessage(message, sendTo);
          setSending(false);
          notification.success({ message: 'Message sent!' });
          setVisible(false);
          setMessage('');
        }}
      >
        <Form>
          <p>Send Message</p>
          <Input.TextArea
            onChange={(e) => setMessage(e.target.value)}
            placeholder={'Write your message...'}
            rows={8}
          />
        </Form>
      </Modal>
    </>
  );
};

export default SendMessageButton;
