import { addDoc, doc, updateDoc } from 'firebase/firestore';
import { Album } from '../../Albums/types';
import { ALBUMS_COLLECTION } from '../constants';

export const createAlbum = async (
  title: string,
  description: string
): Promise<string> => {
  const res = await addDoc(ALBUMS_COLLECTION, {
    title: title,
    description: description,
    images: [],
    id: '', // auto generated on creation
  });

  return res.id;
};

export const addImages = async (album: Album, imageIds: string[]) => {
  await updateDoc(doc(ALBUMS_COLLECTION, album.id), {
    images: [
      ...(album.images ?? []),
      ...imageIds.map((imageId) => ({ id: imageId })),
    ],
  });
};
