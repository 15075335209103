import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { createList } from '../firebase/utils/lists';

interface Props {
  onCreate: (listId: string) => void;
}

const CreateAlbumButton: React.FC<Props> = ({ onCreate }) => {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [creating, setCreating] = useState(false);

  const create = async () => {
    setCreating(true);
    const id = await createList({ title, description, data: [] });
    setCreating(false);
    onCreate(id);
    setVisible(false);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <PlusOutlined />
      </Button>
      <Modal
        onCancel={() => setVisible(false)}
        onOk={create}
        visible={visible}
        okButtonProps={{ loading: creating }}
      >
        <h3>Create New Album</h3>
        <Form onFinish={create}>
          <p>Title</p>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          <p>Description</p>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form>
      </Modal>
    </>
  );
};

export default CreateAlbumButton;
