import { LinkOutlined } from '@ant-design/icons';
import { Modal, Form, Input } from 'antd';
import { useState } from 'react';
import { setItemLink } from '../../firebase/utils/lists';
import { ListItem, List } from '../types';

const AddLinkButton: React.FC<{ list: List; item: ListItem }> = ({
  list,
  item,
}) => {
  const [visible, setVisible] = useState(false);
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);

  const onAdd = async () => {
    setLoading(true);
    await setItemLink(list, item, link);
    setLoading(false);
    setVisible(false);
  };

  return (
    <>
      <LinkOutlined onClick={() => setVisible(true)} />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onAdd}
        okButtonProps={{
          loading,
          htmlType: 'submit',
        }}
      >
        <Form onFinish={onAdd}>
          <p>Add link to {item.item}</p>
          <Input onChange={(e) => setLink(e.target.value)} value={link} />
        </Form>
      </Modal>
    </>
  );
};

export default AddLinkButton;
