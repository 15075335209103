import { ALBUMS_COLLECTION } from '../../firebase/constants';
import useFirebaseQuery from '../../firebase/useFirebaseQuery';
import { Album } from '../types';

const useLists = (): Album[] => {
  const data = useFirebaseQuery(ALBUMS_COLLECTION);

  if (!data) {
    return [];
  }

  return data.map((doc) => doc.data());
};

export default useLists;
