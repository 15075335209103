import { User } from 'firebase/auth';
import { Users } from '../Users/types';

export const getSendTo = (user: User): string => {
  switch (user.email) {
    case Users.Katie:
      return Users.Ryan;
    case Users.Ryan:
      return Users.Katie;
    default:
      return '';
  }
};
