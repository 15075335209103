import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import useAlbums from '../AlbumsPage/useAlbums';
import AlbumThumbnail from '../AlbumThumbnail';

const widgetClassName = css({
  width: '90%',
  display: 'flex',
  justifyContent: 'center',
});

const innerClassName = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const thumbnailsClassName = css({
  overflowX: 'scroll',
  overflowY: 'hidden',
  display: 'flex',
  height: 'auto',
});

const titleClassName = css({
  textDecoration: 'underline',
});

const AlbumsWidget = () => {
  const albums = useAlbums();

  return (
    <div className={widgetClassName}>
      <div className={innerClassName}>
        <Link to='albums'>
          <h2 className={titleClassName}>Photo Albums</h2>
        </Link>
        <div className={thumbnailsClassName}>
          {albums.map((album) => (
            <AlbumThumbnail key={album.id} album={album} size={200} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlbumsWidget;
