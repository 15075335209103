import { css } from '@emotion/css';
import { useNavigate } from 'react-router-dom';
import Page from '../../Page';
import PageHeader from '../../PageHeader';
import AlbumThumbnail from '../AlbumThumbnail';
import CreateAlbumButton from './CreateAlbumButton';
import useAlbums from './useAlbums';

const thumbnailsClassName = css({
  display: 'flex',
  padding: '32px',
});

const AlbumsPage = () => {
  const albums = useAlbums();
  const navigate = useNavigate();

  return (
    <Page>
      <PageHeader title='Our Adventures' rightContent={<CreateAlbumButton />} />
      <div className={thumbnailsClassName}>
        {albums.map((album) => (
          <div key={album.id} onClick={() => navigate(`../album/${album.id}`)}>
            <AlbumThumbnail album={album} size={400} />
          </div>
        ))}
      </div>
    </Page>
  );
};

export default AlbumsPage;
