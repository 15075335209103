import { css } from '@emotion/css';
import Page from '../../Page';
import PageHeader from '../../PageHeader';
import Images from './Images';
import UploadButton from './UploadButton/UploadButton';
import useAlbum from './useAlbum';

const titleClassName = css({
  display: 'flex',
  width: '90%',
  marginLeft: '5%',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const AlbumPage = () => {
  const data = useAlbum();

  switch (data.status) {
    case 'NotFound':
      return null;
    case 'Success':
      return (
        <Page>
          <PageHeader
            title={data.album.title}
            subtitle={data.album.description}
            rightContent={<UploadButton />}
          />
          <Images album={data.album} />
        </Page>
      );
  }
};

export default AlbumPage;
