import { css } from '@emotion/css';
import SendMessageButton from '../Messages/SendMessageButton';

const headerClassName = css({
  width: '100%',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 16px',
});

const Header = () => {
  return (
    <div className={headerClassName}>
      <SendMessageButton />
    </div>
  );
};

export default Header;
