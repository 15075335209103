import { css } from '@emotion/css';
import { ReactNode } from 'react';
import Header from './Header';
import Authorize from './Users/Authorize';

const pageClassName = css({
  backgroundColor: 'lavender',
  minHeight: '100vh',
});

const Page = ({ children }: { children: ReactNode }) => (
  <div className={pageClassName}>
    <Authorize>{children}</Authorize>
  </div>
);

export default Page;
