import { css } from '@emotion/css';
import { Button, Modal } from 'antd';
import { messageSeen } from '../firebase/utils/messages';
import useMessages from './useMessages';

const modalClassName = css({
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  flexDirection: 'column',
});

const MessagesModal = () => {
  const messages = useMessages();

  return (
    <Modal visible={Boolean(messages.length)} footer={null}>
      <div className={modalClassName}>
        <div>{messages[0]?.message}</div>
        <Button onClick={() => messageSeen(messages[0].id)}>Ok</Button>
      </div>
    </Modal>
  );
};

export default MessagesModal;
