import { PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { addToList } from '../../firebase/utils/lists';
import { List } from '../types';
import ItemComponent from './ItemComponent';

const addItemSectionClassName = css({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
});

interface Props {
  list?: List;
}

const ListComponent: React.FC<Props> = ({ list }) => {
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [newItem, setNewItem] = useState('');

  if (!list) {
    return null;
  }

  return (
    <div>
      <Form
        onFinish={async () => {
          setIsAddingItem(true);
          try {
            await addToList(list, newItem);
            setIsAddingItem(false);
            setNewItem('');
          } catch {
            setIsAddingItem(false);
          }
        }}
      >
        <div className={addItemSectionClassName}>
          <Input
            placeholder='Add new item'
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
          />
          <Button loading={isAddingItem} htmlType='submit'>
            <PlusOutlined />
          </Button>
        </div>
      </Form>
      <div>
        {list.data.map((item, idx) => (
          <ItemComponent item={item} list={list} key={idx} />
        ))}
      </div>
    </div>
  );
};

export default ListComponent;
