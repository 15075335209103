import { css } from '@emotion/css';
import useImage from '../Albums/AlbumPage/useImage';
import TimeTogether from './TimeTogether/TimeTogether';

const sectionClassName = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});

const imageClassName = css({
  width: '400px',
  height: '400px',
  borderRadius: '50%',
  overflow: 'hidden',
  objectFit: 'cover',
  marginBottom: 16,
});

const headerImage = { id: 'header.JPG' } as const;

const TopSection = () => {
  const image = useImage(headerImage);

  return (
    <div className={sectionClassName}>
      <h1>Katie &amp; Ryan ❤️</h1>
      <img src={image.data} className={imageClassName} />
      <TimeTogether />
    </div>
  );
};

export default TopSection;
