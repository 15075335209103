import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { AUTH } from '../../firebase/constants';
import { useNavigate } from 'react-router-dom';

const useIsLoggedIn = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(AUTH, (user) => {
      if (user) {
        setLoggedIn(true);
      }
    });
  }, [navigate]);

  return isLoggedIn;
};

export default useIsLoggedIn;
