import { LISTS_COLLECTION } from '../firebase/constants';
import useFirebaseQuery from '../firebase/useFirebaseQuery';
import { List } from './types';

const useLists = (): List[] => {
  const data = useFirebaseQuery(LISTS_COLLECTION);

  if (!data) {
    return [];
  }

  return data.map((doc) => doc.data());
};

export default useLists;
