import React, { useState } from 'react';
import { Album } from './types';
import { useNavigate } from 'react-router-dom';
import useImage from './AlbumPage/useImage';
import { css } from '@emotion/css';

interface Props {
  album: Album;
  size: number;
}

const thumbnailClassName = css({
  cursor: 'pointer',
  height: 'auto',
  position: 'relative',
  marginRight: '24px',
});

const getThumbnailImgClassName = (size: number) =>
  css({
    height: size,
    width: size,
    borderRadius: '4px',
    objectFit: 'cover',
  });

const getThumbnailTitleClassName = (hovered: boolean) =>
  css({
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    visibility: hovered ? 'visible' : 'hidden',
    height: '100%',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    borderRadius: '4px',
  });

const AlbumThumbnail: React.FC<Props> = ({ album, size }) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();
  const data = useImage(album.images[0] ?? { id: '' });

  return (
    <div
      onClick={() => navigate(`../album/${album.id}`)}
      className={thumbnailClassName}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <h3 className={getThumbnailTitleClassName(hovered)}>{album.title}</h3>
      <img src={data.data} className={getThumbnailImgClassName(size)} />
    </div>
  );
};

export default AlbumThumbnail;
