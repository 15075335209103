import intervalToDuration from 'date-fns/intervalToDuration';

export const START_OF_RELATIONSHIP = new Date('2021-10-14');
export const DAY_WE_MET = new Date('2021-05-03');

const NOW = new Date(Date.now());

export const timeSince = (from: Date) => {
  return intervalToDuration({
    start: from,
    end: NOW,
  });
};
