import { onSnapshot, Query, QueryDocumentSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

const useFirebaseQuery = <T>(
  query: Query<T>
): QueryDocumentSnapshot<T>[] | undefined => {
  const [data, setData] = useState<QueryDocumentSnapshot<T>[]>();

  useEffect(() => {
    const unsubscribe = onSnapshot(query, (querySnapshot) => {
      setData(querySnapshot.docs);
    });

    return () => unsubscribe();
  }, []);

  return data;
};

export default useFirebaseQuery;
