import { FirestoreDataConverter } from 'firebase/firestore';
import { Message } from '../../Messages/types';

const messageConverter: FirestoreDataConverter<Message> = {
  toFirestore: (model) => {
    return {
      to: model.to,
      message: model.message,
      seen: model.seen,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data();
    return {
      to: data.to,
      message: data.message,
      seen: data.seen,
      id: snapshot.id,
    };
  },
};

export default messageConverter;
