import heic2any from 'heic2any';

export const processBlob = async (blob: Blob): Promise<Blob> => {
  console.log(blob);
  if (blob.type === 'image/heic') {
    return (await heic2any({ blob })) as Blob;
  }

  return Promise.resolve(blob);
};
