import { updateDoc, setDoc, doc } from 'firebase/firestore';
import { MESSAGES_COLLECTION } from '../constants';

export const messageSeen = async (id: string) => {
  await updateDoc(doc(MESSAGES_COLLECTION, id), {
    seen: true,
  });
};

export const sendMessage = async (message: string, to: string) => {
  const docRef = doc(MESSAGES_COLLECTION);

  await setDoc(docRef, {
    id: '', // id is auto generated at time of creation
    seen: false,
    to,
    message,
  });
};
