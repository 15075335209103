import { FirebaseOptions, initializeApp } from 'firebase/app';
import { collection, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import messageConverter from './converters/messageConverter';
import listConverter from './converters/listConverter';
import albumConverter from './converters/albumConverter';
import { getStorage } from 'firebase/storage';

const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: 'AIzaSyCrAFeumkJZ6TPdxqR9jUVDUGDRDrzw-vE',
  authDomain: 'katie-ryan.firebaseapp.com',
  projectId: 'katie-ryan',
  storageBucket: 'katie-ryan.appspot.com',
  messagingSenderId: '208383211234',
  appId: '1:208383211234:web:78def5eeab8bd22d4e50bb',
  measurementId: 'G-WB35F50FWX',
};

const COLLECTIONS = {
  Messages: 'messages',
  Lists: 'lists',
  Albums: 'albums',
};

const FIREBASE_APP = initializeApp(FIREBASE_CONFIG);
export const DATABASE = getFirestore(FIREBASE_APP);
export const AUTH = getAuth(FIREBASE_APP);

export const MESSAGES_COLLECTION = collection(
  DATABASE,
  COLLECTIONS.Messages
).withConverter(messageConverter);

export const LISTS_COLLECTION = collection(
  DATABASE,
  COLLECTIONS.Lists
).withConverter(listConverter);

// Albums
export const ALBUMS_COLLECTION = collection(
  DATABASE,
  COLLECTIONS.Albums
).withConverter(albumConverter);

export const IMAGES_STORAGE_PATH = 'images';

export const STORAGE = getStorage();
