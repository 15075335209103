import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
} from 'firebase/auth';
import { AUTH } from '../constants';

export const signIn = async (email: string, password: string) => {
  try {
    const user = await signInWithEmailAndPassword(AUTH, email, password);
    return {
      status: 'Success',
      user: user.user,
    };
  } catch (e) {
    return {
      status: 'Error',
    };
  }
};

export const signOut = async () => {
  await firebaseSignOut(AUTH);
};
