import { FirestoreDataConverter } from 'firebase/firestore';
import { Album } from '../../Albums/types';
import { toImageFireStoreModel, toImageInternalModel } from './imageConverter';

const albumConverter: FirestoreDataConverter<Album> = {
  toFirestore: (model: Album) => {
    return {
      title: model.title,
      description: model.description,
      images: model.images.map(toImageFireStoreModel),
    };
  },
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    return {
      description: data.description,
      title: data.title,
      id: snapshot.id,
      data: data.data,
      images: data.images.map(toImageInternalModel),
    };
  },
};

export default albumConverter;
