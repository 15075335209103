import { css } from '@emotion/css';
import { Album, Image } from '../types';
import useImage from './useImage';

interface Props {
  album: Album;
}

const imageClassName = css({
  maxWidth: '700px',
  objectFit: 'cover',
  display: 'flex',
  padding: '16px',
  borderRadius: '20px',
});

const containerClassName = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

const ImageComponent: React.FC<{ image: Image }> = ({ image }) => {
  const data = useImage(image);

  return <img className={imageClassName} src={data.data} />;
};

const Images: React.FC<Props> = ({ album }) => {
  return (
    <div className={containerClassName}>
      {album.images.map((img) => (
        <ImageComponent key={img.id} image={img} />
      ))}
    </div>
  );
};

export default Images;
