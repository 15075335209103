import { css } from '@emotion/css';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import CreateListButton from './CreateListButton';
import ListComponent from './List';
import useLists from './useLists';

const { Option } = Select;

const listsSectionClassName = css({
  height: '500px',
  backgroundColor: 'white',
  padding: '16px',
});

const listSelectionClassName = css({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  paddingBottom: '2px',
});

const listDropdownClassName = css({
  width: '100%',
});

const Lists = () => {
  const data = useLists();
  const [selectedList, setSelectedList] = useState<string>();

  useEffect(() => {
    if (!selectedList) {
      setSelectedList(data[0]?.id);
    }
  }, [data]);

  return (
    <div className={listsSectionClassName}>
      <h3>Bucket Lists</h3>
      <div className={listSelectionClassName}>
        <Select
          value={selectedList}
          defaultValue={data[0]?.id}
          onChange={setSelectedList}
          className={listDropdownClassName}
        >
          {data.map((list) => (
            <Option value={list.id} key={list.id}>
              {list.title}
            </Option>
          ))}
        </Select>
        <CreateListButton onCreate={(id) => setSelectedList(id)} />
      </div>
      <ListComponent list={data.find((list) => list?.id === selectedList)} />
    </div>
  );
};

export default Lists;
