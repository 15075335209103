import Lists from '../Lists/Lists';
import MessagesModal from '../Messages/MessagesModal';
import Module from './Module';
import Playlist from './Playlist';
import { css } from '@emotion/css';
import TopSection from './TopSection';
import AlbumsWidget from '../Albums/AlbumsWidget';
import Page from '../Page';
import Calendar from './Calendar';
import Header from '../Header';

const containerClassName = css({
  backgroundColor: 'lavender',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

const Homepage = () => {
  return (
    <Page>
      <Header />
      <div className={containerClassName}>
        <TopSection />
        <Module>
          <Playlist />
        </Module>
        <Module>
          <Lists />
        </Module>
        <AlbumsWidget />
        <Module>
          <Calendar />
        </Module>
        <MessagesModal />
      </div>
    </Page>
  );
};

export default Homepage;
