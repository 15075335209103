import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createAlbum } from '../../firebase/utils/albums';

const CreateAlbumButton = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [creating, setCreating] = useState(false);

  const create = async () => {
    setCreating(true);
    const id = await createAlbum(title, description);
    setCreating(false);
    navigate(`../album/${id}`);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <PlusOutlined />
      </Button>
      <Modal
        onCancel={() => setVisible(false)}
        onOk={create}
        visible={visible}
        okButtonProps={{ loading: creating }}
      >
        <h3>Create New Album</h3>
        <Form onFinish={create}>
          <p>Title</p>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          <p>Description</p>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form>
      </Modal>
    </>
  );
};

export default CreateAlbumButton;
