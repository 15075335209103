import { updateDoc, arrayUnion, doc, addDoc } from 'firebase/firestore';
import { List, ListItem } from '../../Lists/types';
import { LISTS_COLLECTION } from '../constants';

export const createList = async (list: Omit<List, 'id'>) => {
  const res = await addDoc(LISTS_COLLECTION, list);
  return res.id;
};

export const addToList = async (list: List, item: string) => {
  await updateDoc(doc(LISTS_COLLECTION, list.id), {
    data: arrayUnion({ item, done: false }),
  });
};

export const setListItemStatus = async (
  list: List,
  item: ListItem,
  status: boolean
) => {
  await updateDoc(doc(LISTS_COLLECTION, list.id), {
    data: list.data.map((i) => {
      if (i.item === item.item) {
        return { ...i, done: status };
      }

      return i;
    }),
  });
};

export const setItemLink = async (list: List, item: ListItem, link: string) => {
  await updateDoc(doc(LISTS_COLLECTION, list.id), {
    data: list.data.map((i) => {
      if (i.item === item.item) {
        return { ...i, link };
      }

      return i;
    }),
  });
};

export const removeFromList = async (list: List, item: ListItem) => {
  await updateDoc(doc(LISTS_COLLECTION, list.id), {
    data: list.data.filter((i) => i.item !== item.item),
  });
};
