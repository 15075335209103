import { doc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { ALBUMS_COLLECTION } from '../../firebase/constants';
import useFirebaseDoc from '../../firebase/useFirebaseDoc';
import { Album } from '../types';

type Output = { status: 'NotFound' } | { status: 'Success'; album: Album };

const useAlbum = (): Output => {
  const { albumId } = useParams();

  const docRef = doc(ALBUMS_COLLECTION, albumId);

  const data = useFirebaseDoc(docRef);

  if (!data) {
    return { status: 'NotFound' };
  }

  return {
    status: 'Success',
    album: data,
  };
};

export default useAlbum;
