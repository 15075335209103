const Calendar = () => (
  <iframe
    src='https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&title=Katie%20%26%20Ryan&src=dTA1NzI1b2g5ZmFxMmwzcm9lczYxaTBvZnNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%233F51B5'
    style={{ border: 'solid 1px lavender' }}
    width='800'
    height='600'
    frameBorder='0'
    scrolling='no'
  ></iframe>
);

export default Calendar;
